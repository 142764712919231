import { SpecialHost } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { createUnsavingState } from 'store/entities/adminEvents/unsavedUtils';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<SpecialHost>>();
const initialState = stateFactory.getInitialState({
  ...createUnsavingState(),
});

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const eventHostsReducer = createReducer<State, Actions>(initialState, {
  [actions.getEventHosts.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getEventHosts.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getEventHosts.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getEventHosts.reset.type]: actionsHandlers.handleGetManyReset,
  [actions.getEventHost.request.type]: actionsHandlers.handleGetOneRequest,
  [actions.getEventHost.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getEventHost.failure.type]: actionsHandlers.handleGetOneFailure,
  [actions.getEventHost.reset.type]: actionsHandlers.handleGetOneReset,
});

export default eventHostsReducer;
