import { EventList } from 'api/general/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<EventList>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminSpecialHostRolesReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminSpecialHostRoles.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getAdminSpecialHostRoles.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getAdminSpecialHostRoles.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getAdminSpecialHostRoles.reset.type]: actionsHandlers.handleGetManyReset,
});

export default adminSpecialHostRolesReducer;
