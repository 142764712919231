import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';

export const adminParentOrgSlice = (state: RootState) => state.entities.adminParentOrg;

export const adminParentOrgById = selectorsFactory.createEntityByIdSelector(adminParentOrgSlice);

export const adminParentOrgEntitiesById = selectorsFactory.createEntitiesByIdSelector(adminParentOrgSlice);

export const adminParentOrgCreatingState = selectorsFactory.createCreatingStateSelector(adminParentOrgSlice);
