import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';

import * as keyWindowNames from './keyWindowNames';

export const eventHostsSlice = (state: RootState) => state.entities.eventHosts;

export const eventHostById = selectorsFactory.createEntityByIdSelector(eventHostsSlice);

export const eventHostsState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  eventHostsSlice,
  keyWindowNames.eventHosts,
);
