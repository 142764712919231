import { EventList } from 'api/general/models';
import { actionsFactories } from '../utils';
import { EntityType } from '../types';

export const getAdminSpecialHostRoles = actionsFactories.createGetManyActionsFactory<EntityType<EventList>>()(
  'GET_ADMIN_SPECIAL_HOST_ROLES_REQUEST',
  'GET_ADMIN_SPECIAL_HOST_ROLES_SUCCESS',
  'GET_ADMIN_SPECIAL_HOST_ROLES_FAILURE',
  'GET_ADMIN_SPECIAL_HOST_ROLES_RESET',
);
