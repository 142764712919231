import { EventList } from 'api/general/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<EventList>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminSpecialHostEventsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminOrganizationSpecialHostEvents.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getAdminOrganizationSpecialHostEvents.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getAdminOrganizationSpecialHostEvents.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getAdminOrganizationSpecialHostEvents.reset.type]: actionsHandlers.handleGetPaginatedManyReset,
});

export default adminSpecialHostEventsReducer;
