import { takeLeading } from 'redux-saga/effects';
import { AdminOrganizationSpecialHostsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';

const handleGetAdminSpecialHostRolesRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminSpecialHostRoles,
  request: AdminOrganizationSpecialHostsApi.getSpecialHostRoles,
});

export default function* adminOrganizationsMetaTagsSagas() {
  yield takeLeading(actions.getAdminSpecialHostRoles.request.type, handleGetAdminSpecialHostRolesRequest);
}
