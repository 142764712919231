import { OrganizationType } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { AdminOrganizationParamsType, CreateAdminOrganizationParentParamsType } from './types';
import * as keyWindowNames from './keyWindowNames';

export const getAdminOrganizationParent = actionsFactories.createGetOneActionsFactory<
  EntityType<OrganizationType>,
  AdminOrganizationParamsType
>()(
  'GET_ADMIN_ORG_PARENT_REQUEST',
  'GET_ADMIN_ORG_PARENT_SUCCESS',
  'GET_ADMIN_ORG_PARENT_FAILURE',
  'GET_ADMIN_ORG_PARENT_RESET',
);

export const createAdminOrganizationParent = actionsFactories.createCreateOneActionsFactory<
  EntityType<OrganizationType>,
  CreateAdminOrganizationParentParamsType
>()(
  'CREATE_ADMIN_ORG_PARENT_REQUEST',
  'CREATE_ADMIN_ORG_PARENT_SUCCESS',
  'CREATE_ADMIN_ORG_PARENT_FAILURE',
  'CREATE_ADMIN_ORG_PARENT_RESET',
  keyWindowNames.adminOrg,
);

export const deleteAdminOrganizationParent =
  actionsFactories.createDeleteOneActionsFactory<AdminOrganizationParamsType>()(
    'DELETE_ADMIN_ORG_PARENT_REQUEST',
    'DELETE_ADMIN_ORG_PARENT_SUCCESS',
    'DELETE_ADMIN_ORG_PARENT_FAILURE',
  );
