import { createReducer } from '../../../utils';
import { RootActions } from '../../../types';
import { actionsHandlers, stateFactories } from '../../utils';
import { SpecialHost } from '../../../../api/admin/models';
import * as actions from '../actions';
import { EntityType } from '../../types';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<SpecialHost>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const specialHostsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminOrganizationSpecialHosts.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getAdminOrganizationSpecialHosts.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getAdminOrganizationSpecialHosts.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getAdminOrganizationSpecialHosts.reset.type]: actionsHandlers.handleGetPaginatedManyReset,
  [actions.updateAdminOrganizationSpecialHost.success.type]: (draft, action) => {
    const { id } = action.payload.entity;
    if (draft.byId[id]) {
      draft.byId[id] = { ...draft.byId[id], ...action.payload.entity };
    }
  },
});

export default specialHostsReducer;
