import { SpecialHost } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import {
  createAddIdActionHandler,
  createRemoveIdActionHandler,
  createUnsavingState,
} from 'store/entities/adminEvents/unsavedUtils';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<SpecialHost>>();
const initialState = stateFactory.getInitialState({
  ...createUnsavingState(),
});

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminTicketsReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminEventHosts.request.type]: actionsHandlers.handleGetManyRequest,
  [actions.getAdminEventHosts.success.type]: actionsHandlers.handleGetManySuccess,
  [actions.getAdminEventHosts.failure.type]: actionsHandlers.handleGetManyFailure,
  [actions.getAdminEventHosts.reset.type]: actionsHandlers.handleGetManyReset,
  [actions.getAdminEventHost.request.type]: actionsHandlers.handleGetOneRequest,
  [actions.getAdminEventHost.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getAdminEventHost.failure.type]: actionsHandlers.handleGetOneFailure,
  [actions.getAdminEventHost.reset.type]: actionsHandlers.handleGetOneReset,
  [actions.createAdminEventHost.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createAdminEventHost.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.createAdminEventHost.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createAdminEventHost.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.updateAdminEventHost.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updateAdminEventHost.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.updateAdminEventHost.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updateAdminEventHost.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.runtimeUpdateAdminEventHost.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.runtimeUpdateAdminEventHost.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.runtimeUpdateAdminEventHost.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.runtimeUpdateAdminEventHost.reset.type]: actionsHandlers.handleUpdateOneReset,
  [actions.deleteAdminEventHost.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.deleteAdminEventHost.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.deleteAdminEventHost.failure.type]: actionsHandlers.handleDeleteOneFailure,
  [actions.addUnsavedAdminEventHost.type]: createAddIdActionHandler('unsavedIds'),
  [actions.removeUnsavedAdminEventHost.type]: createRemoveIdActionHandler('unsavedIds'),
  [actions.addIgnoredUnsavingAdminEventHost.type]: createAddIdActionHandler('ignoredUnsavingIds'),
  [actions.removeIgnoredUnsavingAdminEventHost.type]: createRemoveIdActionHandler('ignoredUnsavingIds'),
});

export default adminTicketsReducer;
