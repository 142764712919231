import { call, takeEvery, takeLeading } from 'redux-saga/effects';
import { EventsApi, UserApi } from 'api';
import { getNextPageParams, sagasHandlersFactory } from 'store/entities/utils';
import { sagasHandlersFactory as featureSagasHandlersFactory } from 'store/features/utils';
import * as actions from './actions';
import * as selectors from './selectors';
import { sortQuestions } from './utils';
import { GetUpcomingEventsSpecialHostsParamsType } from './types';

const handleGetEventsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getEvents,
  request: EventsApi.getEvents,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { page_size, ...rest } = params;
    const { page } = yield call(getNextPageParams, page_size, selectors.eventsState(), params);
    return {
      params: {
        ...rest,
        page,
        page_size,
      },
    };
  },
});

const handleGetSearchEventsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getSearchEvents,
  request: EventsApi.getEvents,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { page_size, ...rest } = params;
    const { page } = yield call(getNextPageParams, page_size, selectors.searchEventsState(), params);
    return {
      params: {
        ...rest,
        page,
        page_size,
      },
    };
  },
});

const handleGetContextualEventsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getContextualEvents,
  request: EventsApi.getEvents,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { page_size, ...rest } = params;
    const { page } = yield call(getNextPageParams, page_size, selectors.contextualEventsState(), params);

    return {
      params: {
        ...rest,
        events_interval: false,
        page,
        page_size,
      },
    };
  },
});

const getHostRolesParams = (hostRoles: any): GetUpcomingEventsSpecialHostsParamsType => {
  if (hostRoles === '0')
    return {
      empty_host_roles: true,
    };
  if (Array.isArray(hostRoles) && hostRoles?.includes('0')) {
    return {
      host_roles: hostRoles.filter((role: string) => role !== '0'),
      empty_host_roles: true,
    };
  }
  return {
    host_roles: hostRoles,
  };
};

const handleGetUpcomingEventsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getUpcomingEvents,
  request: EventsApi.getEvents,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { page_size, host_roles, ...rest } = params;
    const host_roles_params: GetUpcomingEventsSpecialHostsParamsType = yield call(getHostRolesParams, host_roles);
    const { page } = yield call(getNextPageParams, page_size, selectors.upcomingEventsState(), params);

    return {
      params: {
        ...host_roles_params,
        ...rest,
        events_interval: false,
        page,
        page_size,
      },
    };
  },
});

const handleGetPastEventsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getPastEvents,
  request: EventsApi.getEvents,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { page_size, ...rest } = params;
    const { page } = yield call(getNextPageParams, page_size, selectors.pastEventsState(), params);

    return {
      params: {
        ...rest,
        events_interval: true,
        page,
        page_size,
      },
    };
  },
});

const handleGetUserFavoriteEventsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getUserFavoriteEvents,
  request: UserApi.getUserFavoriteEvents,
});

const handleGetUserFavoriteUpcomingEventsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getUserFavoriteUpcomingEvents,
  request: UserApi.getUserFavoriteEvents,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { page_size, ...rest } = params;
    const { page } = yield call(getNextPageParams, page_size, selectors.userFavoriteUpcomingEventsState(), params);
    return {
      params: {
        ...rest,
        events_interval: false,
        page,
        page_size,
      },
    };
  },
});

const handleGetUserFavoritePastEventsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getUserFavoritePastEvents,
  request: UserApi.getUserFavoriteEvents,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { page_size, ...rest } = params;
    const { page } = yield call(getNextPageParams, page_size, selectors.userFavoritePastEventsState(), params);
    return {
      params: {
        ...rest,
        events_interval: true,
        page,
        page_size,
      },
    };
  },
});

const handleGetEventRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getEvent,
  request: EventsApi.getEvent,
  transformResponse: (response) => ({
    ...response,
    questions: sortQuestions(response.questions),
  }),
});

const handleToggleFavoriteEventRequest = featureSagasHandlersFactory.createMultipleFeatureRequestHandler({
  actions: actions.toggleFavoriteEvent,
  request: EventsApi.toggleFavoriteEvent,
  requestArgsBuilder: (action) => action.payload.id,
});

const handleGetEventIcsFeedLinkGenerator = featureSagasHandlersFactory.createMultipleFeatureRequestHandler({
  actions: actions.getEventIcsFeedLinkGenerator,
  request: EventsApi.generateEventIcsFeedLinkGenerator,
  requestArgsBuilder: (action) => action.payload.id,
});

export default function* eventsSagas() {
  yield takeEvery(actions.getEventIcsFeedLinkGenerator.request.type, handleGetEventIcsFeedLinkGenerator);
  yield takeLeading(actions.getEvents.request.type, handleGetEventsRequest);
  yield takeLeading(actions.getPastEvents.request.type, handleGetPastEventsRequest);
  yield takeEvery(actions.getUpcomingEvents.request.type, handleGetUpcomingEventsRequest);
  yield takeEvery(actions.getSearchEvents.request.type, handleGetSearchEventsRequest);
  yield takeEvery(actions.getContextualEvents.request.type, handleGetContextualEventsRequest);
  yield takeEvery(actions.getEvent.request, handleGetEventRequest);
  yield takeEvery(actions.toggleFavoriteEvent.request.type, handleToggleFavoriteEventRequest);
  yield takeEvery(actions.getUserFavoriteEvents.request.type, handleGetUserFavoriteEventsRequest);
  yield takeEvery(actions.getUserFavoriteUpcomingEvents.request.type, handleGetUserFavoriteUpcomingEventsRequest);
  yield takeEvery(actions.getUserFavoritePastEvents.request.type, handleGetUserFavoritePastEventsRequest);
}
