import { takeEvery } from 'redux-saga/effects';
import { AdminOrganizationsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';

const handleGetAdminOrgParentRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminOrganizationParent,
  request: AdminOrganizationsApi.getParent,
  requestArgsBuilder: (action) => {
    const { orgId } = action.payload.params;

    return [orgId];
  },
  transformResponse: (response) => ({
    id: response.parent_id,
    ...response,
  }),
});

const handleCreateAdminOrgParentRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createAdminOrganizationParent,
  request: AdminOrganizationsApi.createParent,
  requestArgsBuilder: (action) => {
    const { params } = action.payload;
    const { orgId, new_parent } = params;

    return [orgId, { body: { new_parent } }];
  },
  transformResponse: (response) => ({
    id: response.parent_id,
    ...response,
  }),
});

const handleDeleteAdminOrgParentRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.deleteAdminOrganizationParent,
  request: AdminOrganizationsApi.deleteParent,
  requestArgsBuilder: (action) => {
    const { orgId } = action.payload.params;
    return [orgId];
  },
});

export default function* adminEventImagesSagas() {
  yield takeEvery(actions.getAdminOrganizationParent.request.type, handleGetAdminOrgParentRequest);
  yield takeEvery(actions.createAdminOrganizationParent.request.type, handleCreateAdminOrgParentRequest);
  yield takeEvery(actions.deleteAdminOrganizationParent.request.type, handleDeleteAdminOrgParentRequest);
}
