import { call, takeLeading } from 'redux-saga/effects';
import { AdminOrganizationSpecialHostsApi } from 'api';
import { getNextPageParams, sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';
import * as selectors from './selectors';

const handleGetAdminOrganizationsSpecialHostEventsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminOrganizationSpecialHostEvents,
  request: AdminOrganizationSpecialHostsApi.getOrganizationSpecialHostEvents,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { organizationId, hostId, page_size, ...rest } = params;
    const { page } = yield call(
      getNextPageParams,
      page_size,
      selectors.adminOrganizationSpecialHostEventsState,
      params,
    );

    return [organizationId, hostId, { params: { ...rest, page, page_size } }];
  },
});

export default function* adminOrganizationsMetaTagsSagas() {
  yield takeLeading(
    actions.getAdminOrganizationSpecialHostEvents.request.type,
    handleGetAdminOrganizationsSpecialHostEventsRequest,
  );
}
