import { combineReducers } from 'redux';
import eventsReducer from './eventsReducer';
import toggleFavoriteEventReducer from './toggleFavoriteEventReducer';
import eventIcsFeedLinkGeneratorReducer from './eventIcsFeedLinkGeneratorReducer';
import userFavoriteEventsReducer from './userFavoriteEventsReducer';
import searchEventsReducer from './searchEventsReducer';
import contextualEventsReducer from './contextualEventsReducer';
import eventReducer from './eventReducer';

export default combineReducers({
  entityState: eventReducer,
  entitiesState: eventsReducer,
  searchEventsState: searchEventsReducer,
  contextualEventsState: contextualEventsReducer,
  toggleFavoriteEvent: toggleFavoriteEventReducer,
  eventIcsFeedLinkGenerator: eventIcsFeedLinkGeneratorReducer,
  userFavoriteEvents: userFavoriteEventsReducer,
});
