import { call, takeEvery, takeLeading } from 'redux-saga/effects';
import { AdminTrustProfilesApi } from 'api';
import * as actions from './actions';
import { getNextPageParams, sagasHandlersFactory } from '../utils';
import * as selectors from './selectors';

const handleGetAdminTrustProfilesRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminTrustProfiles,
  request: AdminTrustProfilesApi.getAdminProfiles,
  notifyError: false,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    if (params.user__primary_market__slug === 'other') {
      params.user__primary_market__slug = undefined;
      params.user__primary_market__slug_isnull = true;
    } else {
      params.user__primary_market__slug_isnull = undefined;
    }
    const { page_size, ...rest } = params;
    const { page } = yield call(getNextPageParams, page_size, selectors.adminTrustProfilesState, params);

    return { params: { ...rest, page, page_size } };
  },
});

const handleGetAdminTrustProfileRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminTrustProfile,
  request: AdminTrustProfilesApi.getAdminProfile,
  notifyError: false,
});

const handleUpdateAdminTrustProfileRequest = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.updateAdminTrustProfile,
  request: AdminTrustProfilesApi.updateAdminTrustProfile,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    return [id, { body: { ...params } }];
  },
  transformResponse: (response, action) => ({
    id: action.payload.id,
    ...response,
  }),
});

export default function* adminUsersSagas() {
  yield takeLeading(actions.getAdminTrustProfiles.request.type, handleGetAdminTrustProfilesRequest);
  yield takeLeading(actions.getAdminTrustProfile.request.type, handleGetAdminTrustProfileRequest);
  yield takeEvery(actions.updateAdminTrustProfile.request.type, handleUpdateAdminTrustProfileRequest);
}
