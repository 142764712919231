import { takeEvery } from 'redux-saga/effects';
import { sagasHandlersFactory } from 'store/entities/utils';
import { SpecialHostsApi } from 'api';
import * as actions from './actions';

const handleGetSpecialHostRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getSpecialHost,
  request: SpecialHostsApi.getSpecialHosts,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    return [id, { params }];
  },
});

export default function* adminTicketsSagas() {
  yield takeEvery(actions.getSpecialHost.request.type, handleGetSpecialHostRequest);
}
