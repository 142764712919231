import { Channel } from 'api/admin/models';
import { del, get, patch, post, put } from './client';
import { buildPath, toJson } from './utils';
import { ADMIN_ORGANIZATIONS_PATH, API_ADMIN_URL } from './constants';
import { GeneralListType, IdType, Options } from './types';
import {
  AdminEventCCRequestStatus,
  DefaultLanguageEventDisplay,
  EventCreate,
  EventDisplayList,
  EventOrdersStatistics,
  EventTotalDonationsStatictics,
  EventTotalSalesStatictics,
  EventTotalTicketsStatictics,
  EventUpdate,
  HideEventQuestion,
  OrganizationAdminDetail,
  OrganizationAdminList,
  OrganizationAdminShortList,
  OrganizationImage,
  OrganizationInterestStatistic,
  OrganizationManyTimeQuestionDisplay,
  OrganizationSettings,
  OrganizationStatistics,
  OrganizationType,
  OrganizationVideoDisplay,
  PresignedUrl,
  Report,
  TicketsStatistics,
} from './admin/models';

const EVENTS = 'events';
const ALL_ORGANIZATIONS = 'all-organizations';
const PUBLISH = 'publish';
const MANY_TIME_QUESTIONS = 'many-time-questions';
const EXPORT_ORDERS_CSV = 'export-orders-csv';
const IMAGES = 'images';
const VIDEOS = 'videos';
const SAVE_AS_DRAFT = 'save-as-draft';
const CANCEL = 'cancel';
const ARCHIVE = 'archive';
const UNARCHIVE = 'unarchive';
const MARK_AS_TEST = 'mark-as-test';
const STRIPE_CONNECT = 'stripe-connect';
const STRIPE_OAUTH = 'stripe-oauth';
const STRIPE_EXPRESS_DASHBOARD_LINK = 'stripe-express-dashboard-link';
const CLONE = 'clone';
// const ORDERS_TRANSACTIONS_CSV = 'export-orders-transactions-csv';
const ORDERS_TRANSACTIONS_CSV = 'export-orders-transactions-csv-v2';
const ICS_FEED_LINK_GENERATOR = 'ics-feed-link-generator';
const ORGANIZATION_TYPES = 'organization-types';
const SETTINGS = 'settings';
const HIDE_QUESTION = 'hide-question';
const DASHBOARD = 'dashboard';
const TOTAL_SALES_STATISTICS = 'total-sales-statistics';
const TOTAL_TICKETS_STATISTICS = 'total-tickets-statistics';
const TOTAL_DONATIONS_STATISTICS = 'total-donations-statistics';
const TOTAL_REGISTRANS_STATISTICS = 'total-registrants-statistics';
const TOP_OUTSIDE_ORG_STATISTICS = 'total-people-external-outside-organizations-statistics';
const TICKETS_STATISTICKS = 'tickets-statistics';
const EVENTS_STATISTIC = 'total-events-statistics';
const REPORTS = 'reports';
const REQUEST_LINK = 'request-link';
const KEY = 'key';
const ANSWERS_STATISTICS_BY_KEY = 'answers-statistics-by-key';
const CC_MARK_AS_APPROVE = 'cc-mark-as-approve';
const NEIGHBORHOOD = 'neighborhood';
const GENDER = 'gender';
const AGE = 'age';
const ANSWER_STATISTICS_BY_KEY = 'answer_statistics_by_key';
const TOP_INTERESTS_STATISTIC = 'top-interests-statistics';
const TOP_EVENTS_STATISTIC = 'top-events-registrants-statistics';
const MOST_ACTIVE_PEOPLE_STATISTIC = 'activity-statistics';
const EVENTS_AUTOCOMPLETE = 'events-autocomplete-search';
const SPECIFIC_EVENT = 'specific-event';
const SPREEDLY_CONNECT_MONERIS = 'spreedly-connect-moneris';
const SPREEDLY_CONNECT_STRIPE = 'spreedly-connect-stripe';
const SPREEDLY_CONNECT_AUTHORIZE_NET = 'spreedly-connect-authorize-net';
const SPREEDLY_DISCONNECT_GATEWAY = 'spreedly-disconnect-gateway';
const STRIPE_DISCONNECT = 'stripe-disconnect';
const CHANNELS = 'channels';
const PARENT = 'parent';

export const getOrganizations = (options: any): Promise<GeneralListType<OrganizationAdminList>> =>
  get(ADMIN_ORGANIZATIONS_PATH, options).then(toJson);

export const createOrganization = (options: any): Promise<OrganizationAdminShortList> =>
  post(buildPath(ADMIN_ORGANIZATIONS_PATH), options).then(toJson);

export const getAllOrganizations = (options: any): Promise<OrganizationAdminShortList> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, ALL_ORGANIZATIONS), options).then(toJson);

export const getOrganization = (organizationId: IdType, options: any): Promise<OrganizationAdminDetail> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId), options).then(toJson);

export const partialUpdateOrganization = (organizationId: IdType, options: any): Promise<OrganizationType> =>
  patch(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId), options).then(toJson);

export const getImages = (organizationId: IdType, options: any): Promise<OrganizationImage> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, IMAGES), options).then(toJson);

export const createImage = (organizationId: IdType, options: any): Promise<OrganizationImage> =>
  post(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, IMAGES), options).then(toJson);

export const updateImage = (organizationId: IdType, imageId: IdType, options: any): Promise<OrganizationImage> =>
  put(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, IMAGES, imageId), options).then(toJson);

export const deleteImage = (organizationId: IdType, imageId: IdType, options: any) =>
  del(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, IMAGES, imageId), options);

export const getVideos = (organizationId: IdType, options: any): Promise<OrganizationVideoDisplay> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, VIDEOS), options).then(toJson);

export const createVideo = (organizationId: IdType, options: any): Promise<OrganizationVideoDisplay> =>
  post(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, VIDEOS), options).then(toJson);

export const updateVideo = (organizationId: IdType, videoId: IdType, options: any): Promise<OrganizationVideoDisplay> =>
  put(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, VIDEOS, videoId), options).then(toJson);

export const deleteVideo = (organizationId: IdType, videoId: IdType, options: any) =>
  del(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, VIDEOS, videoId), options);

export const getEvents = (organizationId: IdType, options: any): Promise<GeneralListType<EventDisplayList>> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, EVENTS), options).then(toJson);

export const getOrgEventsAutocomplete = (
  organizationId: IdType,
  options: any,
): Promise<GeneralListType<EventDisplayList>> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, EVENTS_AUTOCOMPLETE), options).then(toJson);

export const getEvent = (organizationId: IdType, eventId: IdType, options: any): Promise<DefaultLanguageEventDisplay> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, EVENTS, eventId), options).then(toJson);

export const createEvent = (organizationId: IdType, options: any): Promise<EventCreate> =>
  post(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, EVENTS), options).then(toJson);

export const partialUpdateEvent = (
  organizationId: IdType,
  eventId: IdType,
  options: Options<EventUpdate>,
): Promise<DefaultLanguageEventDisplay> =>
  patch(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, EVENTS, eventId), options).then(toJson);

export const deleteEvent = (organizationId: IdType, eventId: IdType, options: any) =>
  del(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, EVENTS, eventId), options);

export const cloneAdminEvent = (organizationId: IdType, eventId: IdType, options: any) =>
  post(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, EVENTS, eventId, CLONE), options).then(toJson);

export const publishEvent = (organizationId: IdType, eventId: IdType, options: any): Promise<any> =>
  post(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, EVENTS, eventId, PUBLISH), options).then(toJson);

export const saveEventAsDraft = (organizationId: IdType, eventId: IdType, options: any) =>
  post(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, EVENTS, eventId, SAVE_AS_DRAFT), options).then(toJson);

export const cancelEvent = (organizationId: IdType, eventId: IdType, options: any) =>
  post(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, EVENTS, eventId, CANCEL), options).then(toJson);

export const archiveEvent = (organizationId: IdType, eventId: IdType, options: any) =>
  post(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, EVENTS, eventId, ARCHIVE), options).then(toJson);

export const unarchiveEvent = (organizationId: IdType, eventId: IdType, options: any) =>
  post(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, EVENTS, eventId, UNARCHIVE), options).then(toJson);

// SEPARATE TYPE
export const markAsTestEvent = (organizationId: IdType, eventId: IdType, options: any) =>
  post(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, EVENTS, eventId, MARK_AS_TEST), options).then(toJson);

export const getManyTimeQuestions = (
  organizationId: IdType,
  options: any,
): Promise<GeneralListType<OrganizationManyTimeQuestionDisplay>> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, MANY_TIME_QUESTIONS), options).then(toJson);

export const getManyTimeQuestion = (
  organizationId: IdType,
  questionId: IdType,
  options: any,
): Promise<OrganizationManyTimeQuestionDisplay> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, MANY_TIME_QUESTIONS, questionId), options).then(toJson);

export const createManyTimeQuestion = (
  organizationId: IdType,
  options: any,
): Promise<OrganizationManyTimeQuestionDisplay> =>
  post(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, MANY_TIME_QUESTIONS), options).then(toJson);

export const updateManyTimeQuestion = (
  organizationId: IdType,
  questionId: IdType,
  options: any,
): Promise<OrganizationManyTimeQuestionDisplay> =>
  put(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, MANY_TIME_QUESTIONS, questionId), options).then(toJson);

export const deleteManyTimeQuestion = (organizationId: IdType, questionId: IdType, options: any) =>
  del(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, MANY_TIME_QUESTIONS, questionId), options);

// SEPARATE TYPE
export const requestStripeConnect = (options: any) =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, STRIPE_CONNECT), options).then(toJson);

// SEPARATE TYPE
export const requestOauthAuthorization = (organizationId: IdType, options: any) =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, STRIPE_OAUTH), options).then(toJson);

// SEPARATE TYPE
export const getStripeExpressDashboardLink = (organizationId: IdType, options: any) =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, STRIPE_EXPRESS_DASHBOARD_LINK), options).then(toJson);

export const getOrdersCsv = (organizationId: IdType, eventId: IdType, options: any): Promise<Response> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, EVENTS, eventId, EXPORT_ORDERS_CSV), options);

export const getOrderTransactionsCsv = (organizationId: IdType, eventId: IdType, options: any): Promise<Response> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, EVENTS, eventId, ORDERS_TRANSACTIONS_CSV), options);

// SEPARATE TYPE
export const getIcsFeedLink = (organizationId: IdType, options: any) =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, ICS_FEED_LINK_GENERATOR), options).then(toJson);

export const getOrganizationTypes = (options: any): Promise<OrganizationType> =>
  get(buildPath(API_ADMIN_URL, ORGANIZATION_TYPES), options).then(toJson);

export const getAdminOrganizationSetting = (organizationId: IdType, options: any): Promise<OrganizationSettings> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, SETTINGS), options).then(toJson);

export const getAdminOrganizationSettingSpecificEvent = (
  organizationId: IdType,
  options: any,
): Promise<OrganizationSettings> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, SETTINGS, SPECIFIC_EVENT), options).then(toJson);

export const getAdminEventDashboard = (
  organizationId: IdType,
  eventId: IdType,
  options: any,
): Promise<EventOrdersStatistics> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, EVENTS, eventId, DASHBOARD), options).then(toJson);

export const getAdminEventTotalSalesStatistics = (
  organizationId: IdType,
  eventId: IdType,
  options: any,
): Promise<EventTotalSalesStatictics> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, EVENTS, eventId, TOTAL_SALES_STATISTICS), options).then(
    toJson,
  );

export const getAdminEventTotalTicketsStatistics = (
  organizationId: IdType,
  eventId: IdType,
  options: any,
): Promise<EventTotalTicketsStatictics> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, EVENTS, eventId, TOTAL_TICKETS_STATISTICS), options).then(
    toJson,
  );

export const getAdminEventTotalDonationsStatistics = (
  organizationId: IdType,
  eventId: IdType,
  options: any,
): Promise<EventTotalDonationsStatictics> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, EVENTS, eventId, TOTAL_DONATIONS_STATISTICS), options).then(
    toJson,
  );

export const getAdminEventTicketsStatistics = (
  organizationId: IdType,
  eventId: IdType,
  options: any,
): Promise<TicketsStatistics> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, EVENTS, eventId, TICKETS_STATISTICKS), options).then(toJson);

export const getAdminEventQuestionStatistics = (
  organizationId: IdType,
  eventId: IdType,
  slug: IdType,
  options: any,
): Promise<TicketsStatistics> =>
  get(
    buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, EVENTS, eventId, KEY, slug, ANSWERS_STATISTICS_BY_KEY),
    options,
  ).then(toJson);

export const updateAdminOrganizationSetting = (organizationId: IdType, options: any): Promise<OrganizationSettings> =>
  patch(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, SETTINGS), options).then(toJson);

export const hideAdminQuestion = (organizationId: IdType, eventId: IdType, options: any): Promise<HideEventQuestion> =>
  post(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, EVENTS, eventId, HIDE_QUESTION), options).then(toJson);

export const createAdminOrganizationReport = (organizationId: IdType, options: any): Promise<Report> =>
  post(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, REPORTS), options).then(toJson);

export const getAdminOrganizationReport = (organizationId: IdType, options: any): Promise<GeneralListType<Report>> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, REPORTS), options).then(toJson);

export const getOrganizationReportLink = (
  organizationId: IdType,
  reportId: IdType,
  options: any,
): Promise<PresignedUrl> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, REPORTS, reportId, REQUEST_LINK), options).then(toJson);

export const getAdminOrganizationDashboard = (organizationId: IdType, options: any): Promise<OrganizationStatistics> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, DASHBOARD), options).then(toJson);

export const getAdminOrganizationEventsStatistic = (
  organizationId: IdType,
  options: any,
): Promise<OrganizationStatistics> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, EVENTS_STATISTIC), options).then(toJson);

export const getAdminOrganizationDonationsStatistic = (
  organizationId: IdType,
  options: any,
): Promise<OrganizationStatistics> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, TOTAL_DONATIONS_STATISTICS), options).then(toJson);

export const getAdminOrganizationSalesStatistic = (
  organizationId: IdType,
  options: any,
): Promise<OrganizationStatistics> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, TOTAL_SALES_STATISTICS), options).then(toJson);

export const getAdminOrganizationTicketsStatistic = (
  organizationId: IdType,
  options: any,
): Promise<OrganizationStatistics> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, TOTAL_TICKETS_STATISTICS), options).then(toJson);

export const getAdminOrganizationPeopleStatistic = (
  organizationId: IdType,
  options: any,
): Promise<OrganizationStatistics> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, TOTAL_REGISTRANS_STATISTICS), options).then(toJson);

export const getAdminOrganizationTopOutsideOrgStatistic = (
  organizationId: IdType,
  options: any,
): Promise<OrganizationStatistics> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, TOP_OUTSIDE_ORG_STATISTICS), options).then(toJson);

export const getAdminOrganizationNeighborhoodsStatistic = (
  organizationId: IdType,
  options: any,
): Promise<OrganizationStatistics> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, KEY, NEIGHBORHOOD, ANSWER_STATISTICS_BY_KEY), options).then(
    toJson,
  );

export const getAdminOrganizationGenderStatistic = (
  organizationId: IdType,
  options: any,
): Promise<OrganizationStatistics> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, KEY, GENDER, ANSWER_STATISTICS_BY_KEY), options).then(toJson);

export const getAdminOrganizationAgeStatistic = (
  organizationId: IdType,
  options: any,
): Promise<OrganizationStatistics> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, KEY, AGE, ANSWER_STATISTICS_BY_KEY), options).then(toJson);

export const getAdminOrganizationTopInterestStatistic = (
  organizationId: IdType,
  options: any,
): Promise<OrganizationInterestStatistic[]> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, TOP_INTERESTS_STATISTIC), options).then(toJson);

export const getAdminOrganizationTopEventsStatistic = (
  organizationId: IdType,
  options: any,
): Promise<OrganizationInterestStatistic[]> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, TOP_EVENTS_STATISTIC), options).then(toJson);

export const getAdminOrganizationMostActivePeopleStatistic = (
  organizationId: IdType,
  options: any,
): Promise<OrganizationInterestStatistic[]> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, MOST_ACTIVE_PEOPLE_STATISTIC), options).then(toJson);

export const getAdminOrganizationTicketsRegistrationStatistic = (
  organizationId: IdType,
  options: any,
): Promise<OrganizationInterestStatistic[]> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, TICKETS_STATISTICKS), options).then(toJson);

export const updateEventCCStatus = (
  organizationId: IdType,
  eventId: IdType,
  options: any,
): Promise<AdminEventCCRequestStatus> =>
  post(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, EVENTS, eventId, CC_MARK_AS_APPROVE), options).then(toJson);

export const connectSpreedlyMoneris = (organizationId: IdType, options: any) =>
  post(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, SPREEDLY_CONNECT_MONERIS), options).then((resp) =>
    resp.bodyUsed ? toJson(resp) : {},
  );

export const connectSpreedlyStripe = (organizationId: IdType, options: any) =>
  post(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, SPREEDLY_CONNECT_STRIPE), options).then((resp) =>
    resp.bodyUsed ? toJson(resp) : {},
  );

export const connectSpreedlyAuthorizeNet = (organizationId: IdType, options: any) =>
  post(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, SPREEDLY_CONNECT_AUTHORIZE_NET), options).then((resp) =>
    resp.bodyUsed ? toJson(resp) : {},
  );

export const disconnectSpreedly = (organizationId: IdType, options: any) =>
  post(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, SPREEDLY_DISCONNECT_GATEWAY), options).then((resp) =>
    resp ? toJson(resp) : null,
  );

export const disconnectStripe = (organizationId: IdType, options: any) =>
  post(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, STRIPE_DISCONNECT), options).then((resp) =>
    resp ? toJson(resp) : null,
  );

export const getAdminOrganizationChannels = (organizationId: IdType, options: any): Promise<GeneralListType<Channel>> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, CHANNELS), options).then(toJson);

export const createAdminOrganizationChannel = (organizationId: IdType, options: any) =>
  post(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, CHANNELS), options).then(toJson);

export const deleteAdminOrganizationChannel = (organizationId: IdType, channelId: IdType, options: any) =>
  del(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, CHANNELS, channelId), options);

export const getParent = (organizationId: IdType, options: any) =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, PARENT), options).then(toJson);

export const createParent = (organizationId: IdType, options: any) =>
  post(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, PARENT), options).then(toJson);

export const deleteParent = (organizationId: IdType, options: any) =>
  del(buildPath(ADMIN_ORGANIZATIONS_PATH, organizationId, PARENT), options);
