import { SpecialHost } from 'api/admin/models';
import { createAction } from 'store/utils';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { AdminEventHostsParamsType, AdminEventHostToCreateParamsType } from './types';
import * as keyWindowNames from './keyWindowNames';

export const getAdminEventHosts = actionsFactories.createGetManyActionsFactory<
  EntityType<SpecialHost>,
  AdminEventHostsParamsType
>()(
  'GET_ADMIN_EVENT_HOSTS_REQUEST',
  'GET_ADMIN_EVENT_HOSTS_SUCCESS',
  'GET_ADMIN_EVENT_HOSTS_FAILURE',
  'GET_ADMIN_EVENT_HOSTS_RESET',
  keyWindowNames.adminEventHosts,
);

export const getAdminEventHost = actionsFactories.createGetOneActionsFactory<
  EntityType<SpecialHost>,
  AdminEventHostsParamsType
>()(
  'GET_ADMIN_EVENT_HOST_REQUEST',
  'GET_ADMIN_EVENT_HOST_SUCCESS',
  'GET_ADMIN_EVENT_HOST_FAILURE',
  'GET_ADMIN_EVENT_HOST_RESET',
);

export const createAdminEventHost = actionsFactories.createCreateOneActionsFactory<
  EntityType<SpecialHost>,
  AdminEventHostToCreateParamsType
>()(
  'CREATE_ADMIN_EVENT_HOST_REQUEST',
  'CREATE_ADMIN_EVENT_HOST_SUCCESS',
  'CREATE_ADMIN_EVENT_HOST_FAILURE',
  'CREATE_ADMIN_EVENT_HOST_RESET',
  keyWindowNames.adminEventHosts,
);

export const updateAdminEventHost = actionsFactories.createUpdateOneActionsFactory<
  EntityType<SpecialHost>,
  AdminEventHostsParamsType
>()(
  'UPDATE_ADMIN_EVENT_HOST_REQUEST',
  'UPDATE_ADMIN_EVENT_HOST_SUCCESS',
  'UPDATE_ADMIN_EVENT_HOST_FAILURE',
  'UPDATE_ADMIN_EVENT_HOST_RESET',
);

export const runtimeUpdateAdminEventHost = actionsFactories.createUpdateOneActionsFactory<
  EntityType<SpecialHost>,
  AdminEventHostsParamsType
>()(
  'RUNTIME_UPDATE_ADMIN_EVENT_HOST_REQUEST',
  'RUNTIME_UPDATE_ADMIN_EVENT_HOST_SUCCESS',
  'RUNTIME_UPDATE_ADMIN_EVENT_HOST_FAILURE',
  'RUNTIME_UPDATE_ADMIN_EVENT_HOST_RESET',
);

export const deleteAdminEventHost = actionsFactories.createDeleteOneActionsFactory<AdminEventHostsParamsType>()(
  'DELETE_ADMIN_EVENT_HOST_REQUEST',
  'DELETE_ADMIN_EVENT_HOST_SUCCESS',
  'DELETE_ADMIN_EVENT_HOST_FAILURE',
);

export const addUnsavedAdminEventHost = createAction('ADD_UNSAVED_ADMIN_EVENT_HOST', (id: number) => ({
  payload: {
    id,
  },
}));

export const removeUnsavedAdminEventHost = createAction('REMOVE_UNSAVED_ADMIN_EVENT_HOST', (id: number) => ({
  payload: {
    id,
  },
}));

export const addIgnoredUnsavingAdminEventHost = createAction('ADD_IGNORED_UNSAVING_ADMIN_EVENT_HOST', (id: number) => ({
  payload: {
    id,
  },
}));

export const removeIgnoredUnsavingAdminEventHost = createAction(
  'REMOVE_IGNORED_UNSAVING_ADMIN_EVENT_HOST',
  (id: number) => ({
    payload: {
      id,
    },
  }),
);
