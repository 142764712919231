import { del, get, patch, post } from './client';
import { buildPath, toJson } from './utils';
import { USER_PATH } from './constants';
import { GeneralListType, IdType } from './types';
import { EventList, LanguageSpoken, Occupation, UserInterest } from './general/models';

const USER_DETAIL = 'user-detail';
const LANGUAGES_SPOKEN = 'languages_spoken';
const JEWISH_DESCENDANTS = 'jewish_descendants';
const OCCUPATIONS = 'occupations';
const INTERESTS = 'interests';
const FAVORITE_EVENTS = 'favorite-events';
const PREFERENCES = 'preferences';
const COMMUNICATION_PREFERENCES = 'communication_preferences';
const PASSPORTS = 'passports';
const DELETION_REQUEST = 'deletion-request';

export const getUserDetail = (options: any): Promise<any> =>
  get(buildPath(USER_PATH, USER_DETAIL), options).then(toJson);

export const getUser = (id: IdType, options: any): Promise<any> => get(buildPath(USER_PATH, id), options).then(toJson);

export const getUserInterests = (options: any): Promise<GeneralListType<UserInterest>> =>
  get(buildPath(USER_PATH, INTERESTS), options).then(toJson);

export const getUserFavoriteEvents = (options: any): Promise<GeneralListType<EventList>> =>
  get(buildPath(USER_PATH, FAVORITE_EVENTS), options).then(toJson);

export const getLanguagesSpoken = (options: any): Promise<LanguageSpoken> =>
  get(buildPath(USER_PATH, LANGUAGES_SPOKEN), options).then(toJson);

export const getJewishDescendants = (options: any): Promise<any> =>
  get(buildPath(USER_PATH, JEWISH_DESCENDANTS), options).then(toJson);

export const getOccupations = (options: any): Promise<GeneralListType<Occupation>> =>
  get(buildPath(USER_PATH, OCCUPATIONS), options).then(toJson);

export const partialUpdateUser = (id: IdType, options: any): Promise<any> =>
  patch(buildPath(USER_PATH, id), options).then(toJson);

export const getUserPreferences = (id: IdType, options: any): Promise<any> =>
  get(buildPath(USER_PATH, id, PREFERENCES), options).then(toJson);

export const partialUpdateUserPreferences = (id: IdType, options: any): Promise<any> =>
  patch(buildPath(USER_PATH, id, PREFERENCES), options).then(toJson);

export const getUserPreferencesByEmail = (email: string, options: any): Promise<any> =>
  get(buildPath(USER_PATH, email, COMMUNICATION_PREFERENCES), options).then(toJson);

export const partialUpdateUserPreferencesByEmail = (email: string, options: any): Promise<any> =>
  patch(buildPath(USER_PATH, email, COMMUNICATION_PREFERENCES), options).then(toJson);

export const getUserPassports = (options: any): Promise<any> =>
  get(buildPath(USER_PATH, PASSPORTS), options).then(toJson);

export const createUserPassport = (options: any): Promise<any> =>
  post(buildPath(USER_PATH, PASSPORTS), options).then(toJson);

export const deleteAccount = (options: any): Promise<any> => del(buildPath(USER_PATH, DELETION_REQUEST), options);
