import { takeEvery } from 'redux-saga/effects';
import * as actions from './actions';
import { sagasHandlersFactory } from '../utils';
import { UserApi } from '../../../api';

const handleDeleteAccountRequest = sagasHandlersFactory.createSingleFeatureRequestHandler({
  actions: actions.deleteAccount,
  request: UserApi.deleteAccount,
});

export default function* accountSagas() {
  yield takeEvery(actions.deleteAccount.request.type, handleDeleteAccountRequest);
}
