import { RootState } from 'store/rootReducer';
import { selectorsFactory } from '../utils';
import * as keyWindowNames from './keyWindowNames';

export const adminOrganizationSpecialHostEventsSlice = (state: RootState) =>
  state.entities.adminOrganizationSpecialHostEvents;

export const adminOrganizationSpecialHostEventsState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminOrganizationSpecialHostEventsSlice,
  keyWindowNames.adminOrganizationHostEvents,
);

export const adminOrganizationSpecialHostEventsPaginationState =
  selectorsFactory.createDynamicKeyWindowPaginationSelector(
    adminOrganizationSpecialHostEventsSlice,
    keyWindowNames.adminOrganizationHostEvents,
  );
