import { call, fork, put, select, take, takeEvery, takeLeading } from 'redux-saga/effects';
import { HYDRATE } from 'next-redux-wrapper';
import { AdminEventsApi, AdminOrganizationSpecialHostsApi } from 'api';
import { SpecialHost } from 'api/admin/models';
import { isServer } from 'utils/next';
import { AdminEventsSelectors } from 'store/selectors';
import { sagasHandlersFactory } from 'store/entities/utils';
import { createFakeRequest } from 'store/entities/sagasHelpers';
import { createLoadUnsavedIds, getUnsaved, setUnsaved } from 'store/entities/adminEvents/unsavedUtils';
import * as actions from './actions';
import * as selectors from './selectors';
import { apiCall } from '../../utils';

const UNSAVED_TYPE = 'host';

const loadUnsavedIds = createLoadUnsavedIds({
  type: UNSAVED_TYPE,
  action: actions.addUnsavedAdminEventHost,
});

function getEntityToCreate(entity: any, orgHostId: number): any {
  return {
    bio: entity.bio,
    organization_host: orgHostId,
  };
}

const handleGetAdminEventHostsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminEventHosts,
  request: AdminEventsApi.getEventHosts,
  requestArgsBuilder: (action) => action.payload.params.eventId,
  transformResponse: function* transformResponse(response) {
    const unsavedIds: number[] = yield select(selectors.getUnsavedIds);
    return response.results.reduce((entities: { [x: string]: any }, entity: { id: number }, index: string | number) => {
      if (unsavedIds.includes(entity.id)) {
        const data: any = getUnsaved(UNSAVED_TYPE, entity.id);
        if (data) entities[index] = data;
      }

      return entities;
    }, response.results.slice());
  },
});

const handleGetAdminEventHostRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminEventHost,
  request: AdminEventsApi.getEventHost,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { eventId } = params;
    return [eventId, id];
  },
});

const handleCreateAdminEventHostRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createAdminEventHost,
  request: AdminEventsApi.createEventHost,
  requestArgsBuilder: function* builder(action): any {
    const { params } = action.payload;
    const { organizationId, orgHostId, eventId } = params;

    const response = yield apiCall(
      AdminOrganizationSpecialHostsApi.getOrganizationSpecialHost,
      organizationId,
      orgHostId,
    );
    const newEntity = yield call(getEntityToCreate, response, orgHostId);
    return [eventId, { body: newEntity }];
  },
});

const handleDeleteAdminEventHostRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.deleteAdminEventHost,
  request: AdminEventsApi.deleteEventHost,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { eventId } = params;
    return [eventId, id];
  },
});

const handleUpdateAdminEventHostRequest = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.updateAdminEventHost,
  request: AdminEventsApi.updateEventHost,
  requestArgsBuilder: (action) => {
    const { id, params, entity } = action.payload;
    const { eventId } = params;
    return [eventId, id, { body: entity }];
  },
  notifyError: false,
});

const handleRuntimeUpdateAdminEventHost = sagasHandlersFactory.createRuntimeUpdateHandler({
  actions: actions.runtimeUpdateAdminEventHost,
  request: createFakeRequest(AdminEventsApi.updateEventHost)({
    shouldCallFakeRequest: function* shouldCallFakeRequest(eventId, hostId) {
      const isPublished: boolean = yield select(AdminEventsSelectors.isPublished, Number(eventId));
      const hasIgnoredUnsavingId: boolean = yield select(selectors.hasIgnoredUnsavingId, Number(hostId));
      return isPublished && !hasIgnoredUnsavingId;
    },
    fakeRequest: function* fakeRequest(eventId, hostId, options): any {
      const id = Number(hostId);
      yield put(actions.addUnsavedAdminEventHost(id));
      const adminEventHost: SpecialHost = yield select(selectors.adminEventHostById, id);
      const unsaved = { ...adminEventHost, ...options.body };
      yield call(setUnsaved, UNSAVED_TYPE, id, unsaved);

      return unsaved;
    },
  }),
  requestArgsBuilder: (action) => {
    const { id, params, entity } = action.payload;
    const { eventId } = params;
    return [eventId, id, { body: entity }];
  },
});

export default function* adminTicketsSagas() {
  yield takeLeading(actions.getAdminEventHosts.request.type, handleGetAdminEventHostsRequest);
  yield takeEvery(actions.getAdminEventHost.request.type, handleGetAdminEventHostRequest);
  yield takeEvery(actions.createAdminEventHost.request.type, handleCreateAdminEventHostRequest);
  yield takeEvery(actions.updateAdminEventHost.request.type, handleUpdateAdminEventHostRequest);
  yield takeEvery(actions.deleteAdminEventHost.request.type, handleDeleteAdminEventHostRequest);
  yield fork(handleRuntimeUpdateAdminEventHost);

  if (!isServer()) {
    yield fork(function* wait() {
      yield take(HYDRATE);
      yield call(loadUnsavedIds);
    });
  }
}
