import { SpecialHost } from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { EventHostsParamsType } from './types';
import * as keyWindowNames from './keyWindowNames';

export const getEventHosts = actionsFactories.createGetManyActionsFactory<
  EntityType<SpecialHost>,
  EventHostsParamsType
>()(
  'GET_EVENT_HOSTS_REQUEST',
  'GET_EVENT_HOSTS_SUCCESS',
  'GET_EVENT_HOSTS_FAILURE',
  'GET_EVENT_HOSTS_RESET',
  keyWindowNames.eventHosts,
);

export const getEventHost = actionsFactories.createGetOneActionsFactory<
  EntityType<SpecialHost>,
  EventHostsParamsType
>()('GET_EVENT_HOST_REQUEST', 'GET_EVENT_HOST_SUCCESS', 'GET_EVENT_HOST_FAILURE', 'GET_EVENT_HOST_RESET');
