import { createReducer } from '../../../utils';
import { RootActions } from '../../../types';
import { actionsHandlers, stateFactories } from '../../utils';
import { SpecialHost } from '../../../../api/admin/models';
import * as actions from '../actions';
import { EntityType } from '../../types';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<SpecialHost>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const specialHostsReducer = createReducer<State, Actions>(initialState, {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [actions.getAdminOrganizationSpecialHost.request.type]: actionsHandlers.handleCreateOneRequest,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [actions.getAdminOrganizationSpecialHost.success.type]: actionsHandlers.handleCreateOneSuccess,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [actions.getAdminOrganizationSpecialHost.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.getAdminOrganizationSpecialHost.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.createAdminOrganizationSpecialHost.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createAdminOrganizationSpecialHost.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.createAdminOrganizationSpecialHost.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createAdminOrganizationSpecialHost.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.updateAdminOrganizationSpecialHost.request.type]: actionsHandlers.handleUpdateOneRequest,
  [actions.updateAdminOrganizationSpecialHost.success.type]: actionsHandlers.handleUpdateOneSuccess,
  [actions.updateAdminOrganizationSpecialHost.failure.type]: actionsHandlers.handleUpdateOneFailure,
  [actions.updateAdminOrganizationSpecialHost.reset.type]: actionsHandlers.handleUpdateOneReset,
});

export default specialHostsReducer;
