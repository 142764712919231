import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import * as keyWindowNames from './keyWindowNames';

export const adminOrganizationSpecialHostsSlice = (state: RootState) =>
  state.entities.adminOrganizationSpecialHosts.specialHosts;

export const adminOrganizationSpecialHostSlice = (state: RootState) =>
  state.entities.adminOrganizationSpecialHosts.specialHost;

export const adminOrganizationSpecialHostsState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminOrganizationSpecialHostsSlice,
  keyWindowNames.adminOrganization,
);

export const adminOrganizationSpecialHostCreatingState = selectorsFactory.createCreatingStateSelector(
  adminOrganizationSpecialHostSlice,
);

export const adminOrganizationSpecialHostsPaginationState = selectorsFactory.createDynamicKeyWindowPaginationSelector(
  adminOrganizationSpecialHostsSlice,
  keyWindowNames.adminOrganization,
);

export const adminOrganizationSpecialHostByIdState = selectorsFactory.createEntityStateByIdSelector(
  adminOrganizationSpecialHostSlice,
);
