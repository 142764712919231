import { call, takeLeading } from 'redux-saga/effects';
import { AdminUsersApi } from 'api';
import { getNextPageParams, sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';
import * as selectors from './selectors';

const handleGetAdminPlatformUsersRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminPlatformUsers,
  request: AdminUsersApi.getAdminPlatformUsers,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { page_size, requested_deletion_status, ...rest } = params;
    const custom = {
      is_requested_deletion: requested_deletion_status === 'pending' ? true : undefined,
      is_deleted: requested_deletion_status === 'deleted' ? true : undefined,
    };
    const { page } = yield call(getNextPageParams, page_size, selectors.adminPlatformUsersState, params);

    return { params: { ...custom, ...rest, page, page_size } };
  },
});

export default function* adminPlatformUsersSagas() {
  yield takeLeading(actions.getAdminPlatformUsers.request.type, handleGetAdminPlatformUsersRequest);
}
