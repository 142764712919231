import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import { selectorsFactory as featureSelectorsFactory } from 'store/features/utils';
import * as keyWindowNames from './keyWindowNames';

export const eventSlice = (state: RootState) => state.entities.events.entityState;
export const eventsSlice = (state: RootState) => state.entities.events.entitiesState;

export const eventsListSlice = (state: RootState) => state.entities.events.entitiesState;

export const eventsSearchSlice = (state: RootState) => state.entities.events.searchEventsState;

export const eventsContextualSlice = (state: RootState) => state.entities.events.contextualEventsState;

export const toggleFavoriteEventSlice = (state: RootState) => state.entities.events.toggleFavoriteEvent;

export const userFavoriteEventsSlice = (state: RootState) => state.entities.events.userFavoriteEvents;

export const eventIcsFeedLinkGeneratorSlice = (state: RootState) => state.entities.events.eventIcsFeedLinkGenerator;

export const eventIcsFeedLinkGeneratorState =
  featureSelectorsFactory.createMultipleFeatureStateByIdSelectors(eventIcsFeedLinkGeneratorSlice);

export const eventById = selectorsFactory.createEntityByIdSelector(eventSlice);

export const eventStateById = selectorsFactory.createEntityStateByIdSelector(eventSlice);

export const eventsState = () => selectorsFactory.createStaticKeyWindowStateMemoizedSelector(eventsSlice);

export const eventsPagination = () => selectorsFactory.createStaticKeyWindowPaginationSelector(eventsSlice);

export const searchEventsState = () =>
  selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(eventsSearchSlice, keyWindowNames.searchEvents);
export const contextualEventsState = () =>
  selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(eventsContextualSlice, keyWindowNames.contextualEvents);

export const upcomingEventsState = () =>
  selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(eventsSlice, keyWindowNames.upcomingEvents);

export const upcomingEventsPagination = () =>
  selectorsFactory.createDynamicKeyWindowPaginationSelector(eventsSlice, keyWindowNames.upcomingEvents);

export const pastEventsState = () =>
  selectorsFactory.createStaticKeyWindowStateMemoizedSelector(eventsSlice, keyWindowNames.pastEvents);

export const pastEventsPagination = () =>
  selectorsFactory.createStaticKeyWindowPaginationSelector(eventsSlice, keyWindowNames.pastEvents);

export const userFavoriteEvents = () =>
  selectorsFactory.createStaticKeyWindowEntitiesMemoizedSelector(userFavoriteEventsSlice);

export const userFavoriteEventsState = () =>
  selectorsFactory.createStaticKeyWindowStateMemoizedSelector(userFavoriteEventsSlice);

export const hasUserFavoriteEvents = selectorsFactory.createStaticKeyWindowHasEntitiesSelector(userFavoriteEventsSlice);

export const userFavoriteUpcomingEventsState = () =>
  selectorsFactory.createStaticKeyWindowStateMemoizedSelector(
    userFavoriteEventsSlice,
    keyWindowNames.userFavoriteUpcomingEvents,
  );

export const userFavoriteUpcomingEventsPagination = () =>
  selectorsFactory.createStaticKeyWindowPaginationSelector(
    userFavoriteEventsSlice,
    keyWindowNames.userFavoriteUpcomingEvents,
  );

export const userFavoritePastEventsState = () =>
  selectorsFactory.createStaticKeyWindowStateMemoizedSelector(
    userFavoriteEventsSlice,
    keyWindowNames.userFavoritePastEvents,
  );

export const userFavoritePastEventsPagination = () =>
  selectorsFactory.createStaticKeyWindowPaginationSelector(
    userFavoriteEventsSlice,
    keyWindowNames.userFavoritePastEvents,
  );
