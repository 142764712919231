import { IdType } from './types';
import { Metatag } from './general/models';
import { get, patch, post } from './client';
import { buildPath, toJson } from './utils';
import { ADMIN_ORGANIZATIONS_PATH, API_ADMIN_URL } from './constants';

const SPECIAL_HOSTS = 'hosts';
const EVENTS = 'events';
const HOST_ROLES = 'host-roles';

export const getOrganizationSpecialHosts = (orgId: IdType, options: any): Promise<Metatag[]> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, orgId, SPECIAL_HOSTS), options).then(toJson);

export const getOrganizationSpecialHost = (orgId: IdType, id: IdType, options: any): Promise<Metatag[]> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, orgId, SPECIAL_HOSTS, id), options).then(toJson);

export const createOrganizationSpecialHosts = (orgId: IdType, options: any): Promise<Metatag[]> =>
  post(buildPath(ADMIN_ORGANIZATIONS_PATH, orgId, SPECIAL_HOSTS), options).then(toJson);

export const updateOrganizationSpecialHosts = (orgId: IdType, id: IdType, options: any): Promise<Metatag> =>
  patch(buildPath(ADMIN_ORGANIZATIONS_PATH, orgId, SPECIAL_HOSTS, id), options).then(toJson);

export const getOrganizationSpecialHostEvents = (orgId: IdType, hostId: IdType, options: any): Promise<Metatag[]> =>
  get(buildPath(ADMIN_ORGANIZATIONS_PATH, orgId, SPECIAL_HOSTS, hostId, EVENTS), options).then(toJson);

export const getSpecialHostRoles = (options: any): Promise<Metatag[]> =>
  get(buildPath(API_ADMIN_URL, HOST_ROLES), options).then(toJson);
