import { RootState } from 'store/rootReducer';
import { selectorsFactory } from 'store/entities/utils';
import {
  createGetUnsavedIdsSelector,
  createHasIgnoredUnsavingIdSelector,
  createHasUnsavedIdSelector,
} from 'store/entities/adminEvents/unsavedUtils';

import * as keyWindowNames from './keyWindowNames';

export const adminEventHostsSlice = (state: RootState) => state.entities.adminEventHosts;

export const adminEventHostById = selectorsFactory.createEntityByIdSelector(adminEventHostsSlice);

export const adminEventHostUpdatingErrorById = selectorsFactory.createUpdatingErrorByIdSelector(adminEventHostsSlice);

export const adminHostIsUpdatingById = selectorsFactory.createIsUpdatingByIdSelector(adminEventHostsSlice);

export const adminHostCreatingState = selectorsFactory.createCreatingStateSelector(adminEventHostsSlice);

export const adminHostsChangingState = selectorsFactory.createChangingStateSelector(adminEventHostsSlice);

export const adminEventHostsState = selectorsFactory.createDynamicKeyWindowStateMemoizedSelector(
  adminEventHostsSlice,
  keyWindowNames.adminEventHosts,
);

export const adminEventHosts = selectorsFactory.createDynamicKeyWindowEntitiesMemoizedSelector(
  adminEventHostsSlice,
  keyWindowNames.adminEventHosts,
);

export const adminEventHostsIds = selectorsFactory.createDynamicKeyWindowEntitiesIdsSelector(
  adminEventHostsSlice,
  keyWindowNames.adminEventHosts,
);

export const getUnsavedIds = createGetUnsavedIdsSelector(adminEventHostsSlice);

export const hasUnsavedId = createHasUnsavedIdSelector(adminEventHostsSlice);

export const hasIgnoredUnsavingId = createHasIgnoredUnsavingIdSelector(adminEventHostsSlice);

export const eventHostsStateOrgHostIds = (state: RootState, eventId: number) => {
  const hosts = adminEventHosts(state, { eventId });
  return hosts.map((host) => host.organization_host.id);
};
