import { OrganizationType } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<OrganizationType>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminParentOrgReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminOrganizationParent.request.type]: actionsHandlers.handleGetOneRequest,
  [actions.getAdminOrganizationParent.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getAdminOrganizationParent.failure.type]: actionsHandlers.handleGetOneFailure,
  [actions.getAdminOrganizationParent.reset.type]: actionsHandlers.handleGetOneReset,
  [actions.createAdminOrganizationParent.request.type]: actionsHandlers.handleCreateOneRequest,
  [actions.createAdminOrganizationParent.success.type]: actionsHandlers.handleCreateOneSuccess,
  [actions.createAdminOrganizationParent.failure.type]: actionsHandlers.handleCreateOneFailure,
  [actions.createAdminOrganizationParent.reset.type]: actionsHandlers.handleCreateOneReset,
  [actions.deleteAdminOrganizationParent.request.type]: actionsHandlers.handleDeleteOneRequest,
  [actions.deleteAdminOrganizationParent.success.type]: actionsHandlers.handleDeleteOneSuccess,
  [actions.deleteAdminOrganizationParent.failure.type]: actionsHandlers.handleDeleteOneFailure,
});

export default adminParentOrgReducer;
