import { EventList } from 'api/general/models';
import { actionsFactories } from '../utils';
import { EntityType } from '../types';
import * as keyWindowNames from './keyWindowNames';

export const getAdminOrganizationSpecialHostEvents = actionsFactories.createGetPaginatedManyActionsFactory<
  EntityType<EventList>
>()(
  'GET_ADMIN_ORGANIZATIONS_SPECIAL_HOST_EVENTS_REQUEST',
  'GET_ADMIN_ORGANIZATIONS_SPECIAL_HOST_EVENTS_SUCCESS',
  'GET_ADMIN_ORGANIZATIONS_SPECIAL_HOST_EVENTS_FAILURE',
  'GET_ADMIN_ORGANIZATIONS_SPECIAL_HOST_EVENTS_RESET',
  keyWindowNames.adminOrganizationHostEvents,
);
