import { takeEvery, takeLeading } from 'redux-saga/effects';
import { EventsApi } from 'api';
import { sagasHandlersFactory } from 'store/entities/utils';
import * as actions from './actions';

const handleGetEventHostsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getEventHosts,
  request: EventsApi.getEventHosts,
  requestArgsBuilder: (action) => action.payload.params.eventId,
});

const handleGetEventHostRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getEventHost,
  request: EventsApi.getEventHost,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { eventId } = params;
    return [eventId, id];
  },
});

export default function* adminTicketsSagas() {
  yield takeLeading(actions.getEventHosts.request.type, handleGetEventHostsRequest);
  yield takeEvery(actions.getEventHost.request.type, handleGetEventHostRequest);
}
