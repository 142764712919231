import { SpecialHost } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { createUnsavingState } from 'store/entities/adminEvents/unsavedUtils';
import * as actions from './actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<SpecialHost>>();
const initialState = stateFactory.getInitialState({
  ...createUnsavingState(),
});

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const specialHostsReducer = createReducer<State, Actions>(initialState, {
  [actions.getSpecialHost.request.type]: actionsHandlers.handleGetOneRequest,
  [actions.getSpecialHost.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getSpecialHost.failure.type]: actionsHandlers.handleGetOneFailure,
  [actions.getSpecialHost.reset.type]: actionsHandlers.handleGetOneReset,
});

export default specialHostsReducer;
