import { actionsFactories } from 'store/entities/utils';
import { SpecialHost, SpecialHostToCreateUpdate } from 'api/admin/models';
import { EntityType } from '../types';
import { AdminOrganizationSpecialHostsParamsType } from './types';
import * as keyWindowNames from './keyWindowNames';

export const getAdminOrganizationSpecialHosts = actionsFactories.createGetPaginatedManyActionsFactory<
  EntityType<SpecialHost>
>()(
  'GET_ADMIN_ORGANIZATIONS_SPECIAL_HOSTS_REQUEST',
  'GET_ADMIN_ORGANIZATIONS_SPECIAL_HOSTS_SUCCESS',
  'GET_ADMIN_ORGANIZATIONS_SPECIAL_HOSTS_FAILURE',
  'GET_ADMIN_ORGANIZATIONS_SPECIAL_HOSTS_RESET',
  keyWindowNames.adminOrganization,
);

export const getAdminOrganizationSpecialHost = actionsFactories.createGetOneActionsFactory<
  EntityType<SpecialHost>,
  AdminOrganizationSpecialHostsParamsType
>()(
  'GET_ADMIN_ORGANIZATIONS_SPECIAL_HOST_REQUEST',
  'GET_ADMIN_ORGANIZATIONS_SPECIAL_HOST_SUCCESS',
  'GET_ADMIN_ORGANIZATIONS_SPECIAL_HOST_FAILURE',
  'GET_ADMIN_ORGANIZATIONS_SPECIAL_HOST_RESET',
);

export const createAdminOrganizationSpecialHost = actionsFactories.createCreateOneActionsFactory<
  EntityType<SpecialHostToCreateUpdate>,
  AdminOrganizationSpecialHostsParamsType
>()(
  'CREATE_ADMIN_ORGANIZATIONS_SPECIAL_HOSTS_REQUEST',
  'CREATE_ADMIN_ORGANIZATIONS_SPECIAL_HOSTS_SUCCESS',
  'CREATE_ADMIN_ORGANIZATIONS_SPECIAL_HOSTS_FAILURE',
  'CREATE_ADMIN_ORGANIZATIONS_SPECIAL_HOSTS_RESET',
);

export const updateAdminOrganizationSpecialHost = actionsFactories.createUpdateOneActionsFactory<
  EntityType<SpecialHostToCreateUpdate>,
  AdminOrganizationSpecialHostsParamsType
>()(
  'UPDATE_ADMIN_ORGANIZATIONS_SPECIAL_HOSTS_REQUEST',
  'UPDATE_ADMIN_ORGANIZATIONS_SPECIAL_HOSTS_SUCCESS',
  'UPDATE_ADMIN_ORGANIZATIONS_SPECIAL_HOSTS_FAILURE',
  'UPDATE_ADMIN_ORGANIZATIONS_SPECIAL_HOSTS_RESET',
);
