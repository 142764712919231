import { call, takeEvery, takeLeading } from 'redux-saga/effects';
import { AdminOrganizationSpecialHostsApi, UploadApi } from 'api';
import * as actions from './actions';
import { getNextPageParams, sagasHandlersFactory } from '../utils';
import * as selectors from './selectors';
import { apiCall } from '../../utils';

const handleGetAdminOrganizationsSpecialHostsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminOrganizationSpecialHosts,
  request: AdminOrganizationSpecialHostsApi.getOrganizationSpecialHosts,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { organizationId, page_size, ...rest } = params;
    const { page } = yield call(getNextPageParams, page_size, selectors.adminOrganizationSpecialHostsState, params);

    return [organizationId, { params: { ...rest, page, page_size } }];
  },
});

const handleGetAdminOrganizationsSpecialHostRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.getAdminOrganizationSpecialHost,
  request: AdminOrganizationSpecialHostsApi.getOrganizationSpecialHost,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { organizationId } = params;
    return [organizationId, id];
  },
});

const handleCreateAdminOrganizationsSpecialHostsRequest = sagasHandlersFactory.createCreateOneRequestHandler({
  actions: actions.createAdminOrganizationSpecialHost,
  request: AdminOrganizationSpecialHostsApi.createOrganizationSpecialHosts,
  requestArgsBuilder: function* builder({ payload: { entity, params } }): any {
    const { organizationId, file } = params;

    let response = null;

    if (file) {
      response = yield apiCall(UploadApi.upload, {
        form: {
          file,
          image_type: 'avatar',
        },
      });
    }
    return [organizationId, { body: { ...entity, ...(response?.id && { image: response.id }) } }];
  },
});

const handleUpdateAdminOrganizationsSpecialHostsRequest = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.updateAdminOrganizationSpecialHost,
  request: AdminOrganizationSpecialHostsApi.updateOrganizationSpecialHosts,
  requestArgsBuilder: function* builder({ payload: { id, entity, params } }): any {
    const { organizationId, file } = params;

    let response = null;

    if (file) {
      response = yield apiCall(UploadApi.upload, {
        form: {
          file,
          image_type: 'avatar',
        },
      });
    }
    return [organizationId, id, { body: { ...entity, ...(response?.id && { image: response.id }) } }];
  },
});

export default function* adminOrganizationsMetaTagsSagas() {
  yield takeLeading(
    actions.getAdminOrganizationSpecialHosts.request.type,
    handleGetAdminOrganizationsSpecialHostsRequest,
  );
  yield takeEvery(actions.getAdminOrganizationSpecialHost.request.type, handleGetAdminOrganizationsSpecialHostRequest);
  yield takeEvery(
    actions.createAdminOrganizationSpecialHost.request.type,
    handleCreateAdminOrganizationsSpecialHostsRequest,
  );
  yield takeEvery(
    actions.updateAdminOrganizationSpecialHost.request.type,
    handleUpdateAdminOrganizationsSpecialHostsRequest,
  );
}
