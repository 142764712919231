import { Event } from 'api/general/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { actionsHandlers, stateFactories } from 'store/entities/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<Event>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const eventsReducer = createReducer<State, Actions>(initialState, {
  [actions.getPastEvents.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getPastEvents.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getPastEvents.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getPastEvents.reset.type]: actionsHandlers.handleGetPaginatedManyReset,
  [actions.getEvents.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getEvents.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getEvents.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getEvents.reset.type]: actionsHandlers.handleGetPaginatedManyReset,
  [actions.getUpcomingEvents.request.type]: actionsHandlers.handleGetPaginatedManyRequest,
  [actions.getUpcomingEvents.success.type]: actionsHandlers.handleGetPaginatedManySuccess,
  [actions.getUpcomingEvents.failure.type]: actionsHandlers.handleGetPaginatedManyFailure,
  [actions.getUpcomingEvents.reset.type]: actionsHandlers.handleGetPaginatedManyReset,
  [actions.toggleFavoriteEvent.success.type]: (draft, action) => {
    const event: any = draft.byId[action.payload.id];
    if (event) event.user_favorite = !event.user_favorite;
  },
});

export default eventsReducer;
